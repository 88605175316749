import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FileUpload',
  data () {
    return {
      fileUrl: null,
      s3Request: null,
      supportedFormats: {
        'text/plain': 'TXT',
        'video/mp4': 'MP4',
        'audio/mpeg': 'MP3',
        'image/jpeg': 'JPEG',
        'image/jpg': 'JPG',
        'image/png': 'PNG',
        'application/pdf': 'PDF',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/msword': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
      },
      progressBarStatus: 80,
      showProgressBar: false,
      maxProgressBarValue: 100,
      filePreviewUri: '',
      isFileUploading: false,
      fileExtension: '',
      fileName: '',
      showPreviewModal: false,
      showUploadedStatus: false,
      imagePayload: ''
    }
  },
  computed: {
    ...mapGetters([
      'getMessageAttachment',
      'getFileUploadUrl'
    ])
  },
  methods: {
    ...mapActions([
      'GET_PRESIGNED_URL',
      'SET_ATTACHMENT_MODAL_STATE',
      'SET_MESSAGE_ATTACHMENT',
      'UPLOAD_TO_S3'
    ]
    ),
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      console.log('file is on change' + JSON.stringify(files[0].name))
      this.uploadToS3(files[0], files[0].type)
      // type Validations
      // SIZE vaidation
      // Get PresendUrl APi
      // Put Api in response to PresendUrl response
    },
    uploadToS3 (file, extension) {
      this.isFileUploading = true
      this.openPreviewModal()
      console.log('Extension is ' + this.supportedFormats[extension])
      if (this.supportedFormats[extension]) {
        this.fileExtension = this.supportedFormats[extension]
        this.fileName = file.name
        var presignedPayload = { 'extension': this.supportedFormats[extension],
          'preSignedURLSource': 'MESSAGES' }
        console.log('Payload Presigned' + presignedPayload)
        this.GET_PRESIGNED_URL({
          payload: presignedPayload,
          success: () => { this.makeUploadCall(file, extension) }
        })
      }
    },
    makeUploadCall (file, extension) {
      this.s3Request = {}
      console.log('In Success Call')
      // var formData
      this.s3Request.uploadUri = this.getFileUploadUrl
      this.s3Request.header = extension
      // formData.append('file', file)
      console.log('Presigned Url' + this.getFileUploadUrl)
      console.log('s3 req' + JSON.stringify(this.s3Request))
      this.UPLOAD_TO_S3({
        payload: this.s3Request,
        data: file,
        success: () => {
          this.progressBarStatus = 100
          setTimeout(() => {
            this.isFileUploading = false
            this.showUploadedStatus = true
            this.showFilePreview()
          }
          , 2000
          )
        }
      })
    },
    checkFileType (extension) {
      if (extension === 'JPEG' || extension === 'JPG' || extension === 'PNG') {
        return 'IMAGE'
      } else if (extension === 'TXT' || extension === 'PDF' || extension === 'PPT' || extension === 'PPTX' ||
          extension === 'DOC' || extension === 'DOCX' || extension === 'XLS' || extension === 'XLSX') {
        return 'DOC'
      } else if (extension === 'MP4') {
        return 'VIDEO'
      } else if (extension === 'MP3') {
        return 'AUDIO'
      } else {
        return 'UNSUPPORTED'
      }
    },
    openPreviewModal () {
      this.showPreviewModal = true
    },
    showFilePreview () {
      const regex = 'https:[a-zA-Z-.0-9\\/]+'
      this.filePreviewUri = this.getFileUploadUrl.match(regex)[0]
      console.log('Url is' + this.filePreviewUri)
    },
    sendAttachment () {
      var attachmentPayload = this.createAttachmentPayload()
      console.log('send attachment' + attachmentPayload)
      this.SET_MESSAGE_ATTACHMENT({
        payload: attachmentPayload,
        success: () => {
          this.showUploadedStatus = false
          this.showPreviewModal = false
          this.$store.dispatch('SET_ATTACHMENT_MODAL_STATE', false)
        }
      })
    },
    createAttachmentPayload () {
      var payload
      if (this.checkFileType(this.fileExtension) === 'IMAGE') {
        let stuff = ' width="300px" height="300px">'
        let closingImg = '</img>'
        payload = '<img src="' + this.filePreviewUri + '"' + stuff + closingImg
        return payload
      } else if (this.checkFileType(this.fileExtension) === 'VIDEO') {
        let stuff = '" type="video/mp4"></video></div>'
        payload = '<div><video width="320" height="240" controls><source src="' + this.filePreviewUri + '"' + stuff
        return payload
      } else if (this.checkFileType(this.fileExtension) === 'DOC') {
        let stuff = '</a></div>'
        payload = '<div><img style="margin-right: 5px" height="20px" src="https://pub-lxp-account.s3.amazonaws.com/ASSETS/document.png"/><a target="_blank" href="' + this.filePreviewUri + '">' + this.fileName + stuff
        return payload
      } else if (this.checkFileType(this.fileExtension) === 'AUDIO') {
        let stuff = '"></audio></div>'
        payload = '<div><audio width="100%" height="100%" preload="metadata" controls=""><source src="' + this.filePreviewUri + '"' + stuff
        return payload
      }
    }
  }
}
