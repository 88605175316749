<template>
  <div>
    <div class="d-flex align-items-center contact-box" v-if="(choice==='CONTACT') && item.users[0]" @click="contactCLicked">
      <div class="avatar mr-3">
        <img :src="item.users[0].avatarUrl" :alt="item.users[0].avatarUrl" class="avatar-50 ">
      </div>
      <div class="chat-sidebar-name">
        <h6 class="mb-0 font-light">{{ item.users[0].name }}</h6>
        <div class="" v-if="unread>0">
          <p class="mb-0" v-if="unread===1"><span class="unread-messages-text">{{unread}} New Message</span> </p>
          <p class="mb-0" v-else><span class="unread-messages-text">{{unread}} New Messages</span> </p>
        </div>
      </div>
      <div class="chat-meta float-right text-center mt-2 mr-1 font-light">
        <span class="text-nowrap">{{item.lastPostedAt.split(' ')[0]}}</span>
        <p class="text-nowrap contact-time-font">{{item.lastPostedAt.split(' ')[1]}}</p>
      </div>
    </div>
    <div class="d-flex" v-if="( choice==='SEARCHED_CONTACT')" @click="contactCLickedSearch">
      <div class="avatar mr-3" v-if="item.avatarUrl!== ''">
        <img :src="item.avatarUrl" :alt="item.avatarUrl" class="avatar-50 ">
      </div>
      <div class="avatar mr-3" v-else>
        <img :src="defaultImage" :alt="defaultImage" class="avatar-50 ">
      </div>
      <div class="chat-sidebar-name">
        <h6 class="mb-0 font-light">{{ item.name }}</h6>
      </div>
    </div>
    <div class="d-flex" v-if="(choice==='SEARCHED_MESSAGE')" @click="contactCLickedSearch">
      <div class="avatar mr-3">
        <img :src="item.avatarUrl" :alt="item.avatarUrl" class="avatar-50 ">
      </div>
      <div class="chat-sidebar-name">
        <h6 class="mb-0 font-light">{{ item.name }}</h6>
        <p class="mb-0 font-light limit-2">{{item.message}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ChatItem',
  props: {
    item: {
      type: Object
    },
    choice: {
      type: String,
      required: true
    },
    searchTerm: {
      type: String
    }
  },
  data () {
    return {
      defaultImage: require('../../assets/images/svg/placeholderprofile.svg'),
      payload: [],
      unread: 0,
      currentNotificationObject: {},
      notificationArray: []
    }
  },
  computed: {
    ...mapGetters([
      'getContacts',
      'getContactPageData',
      'getNotification'
    ]) },
  methods: {
    ...mapActions([
      'GET_USER_MESSAGE_HISTORY_SECOND',
      'SET_CURRENT_CHAT_ID',
      'GET_CONTACTS',
      'MARK_AS_READ',
      'SET_CURRENT_PAGE',
      'SET_SEARCHED_MESSAGE',
      'SET_CURRENT_ACTIVE_CHAT_USER_STATE',
      'SET_INDIVIDUAL_NOTIFICATION_COUNT',
      'GET_USER_MESSAGE_HISTORY',
      'SET_CHAT_HISTORY_LOADING'
    ]
    ),
    contactCLicked () {
      if (this.unread > 0 && this.currentNotificationObject) {
        this.SET_INDIVIDUAL_NOTIFICATION_COUNT({
          payload: this.unread
        })
        this.markAsRead()
      }
      console.log('Contact Click', this.item)
      this.$store.dispatch('SET_CURRENT_PAGE', 0)
      this.$store.dispatch('SET_CURRENT_ACTIVE_CHAT_USER_STATE', this.item)
      this.$store.dispatch('SET_CHAT_HISTORY_LOADING', false)
      this.GET_USER_MESSAGE_HISTORY({
        topicId: this.item.chatId,
        success: () => {
          this.$store.dispatch('SET_CHAT_HISTORY_LOADING', true)
          this.$store.dispatch('SET_CURRENT_CHAT_ID', this.item.chatId)
        }
      })
    },
    contactCLickedSearch () {
      console.log('Clicked from Search')
      console.log('Item is', this.item)
      this.$store.dispatch('SET_SEARCHED_MESSAGE', [])
      if (this.item.avatarUrl === '' || this.item.avatarUrl === null) {
        this.item.avatarUrl = this.defaultImage
      }
      this.$store.dispatch('SET_CURRENT_ACTIVE_CHAT_USER_STATE', this.item)
      this.$store.dispatch('SET_CHAT_HISTORY_LOADING', false)
      if ('topicId' in this.item && !(this.choice === 'SEARCHED_MESSAGE')) {
        this.GET_USER_MESSAGE_HISTORY({
          topicId: this.item.topicId,
          success: () => {
            this.$store.dispatch('SET_CHAT_HISTORY_LOADING', true)
            this.$store.dispatch('SET_CURRENT_CHAT_ID', this.item.topicId)
            document.getElementById(this.item.postId).scrollIntoView()
          }
        })
      } else {
        console.log('New Contact')
        this.$store.dispatch('SET_CHAT_HISTORY_LOADING', true)
      }
      if (this.choice === 'SEARCHED_MESSAGE') {
        console.log('In Searched message condition')
        this.GET_USER_MESSAGE_HISTORY({
          topicId: this.item.topicId,
          success: () => {
            console.log('Suceess Message History', this.getContacts)
            let contact
            for (contact of this.getContacts) {
              if (contact.users[0].topicId === this.item.topicId) {
                console.log('Passing Contact', contact.users[0])
                this.$store.dispatch('SET_CURRENT_ACTIVE_CHAT_USER_STATE', contact.users[0])
              }
            }
            this.$store.dispatch('SET_CURRENT_CHAT_ID', this.item.topicId)
            const pages = Object.keys(this.getContactPageData)
            let page
            for (page in pages) {
              if (this.getContactPageData[page].includes(this.item.postId)) {
                break
              }
            }
            setTimeout(() => {
              document.getElementById(this.item.postId).scrollIntoView()
            }, 3000)
            this.GET_USER_MESSAGE_HISTORY_SECOND({
              topicId: this.getContactId,
              payload: this.this.getContactPageData[page],
              option: 'NEW',
              success: () => {
                this.$store.dispatch('SET_CHAT_HISTORY_LOADING', true)
              }
            })
          }
        })
      }
    },
    markAsRead () {
      this.MARK_AS_READ({
        payload: this.currentNotificationObject,
        success: () => {
          this.unread = 0
          setTimeout(() => {
            console.log('Setting to Zero')
            this.SET_INDIVIDUAL_NOTIFICATION_COUNT({
              payload: 0
            })
          }, 5000)
        }
      })
    }

  },
  mounted () {
    if (this.getNotification) {
      let notification
      for (notification of this.getNotification) {
        let notificationObjects = notification.notifications
        for (let notificationObject of notificationObjects) {
          if (notificationObject.chatId === this.item.chatId) {
            this.unread = this.unread + 1
          }
        }
        if (this.unread > 0) {
          this.currentNotificationObject.id = notification.id
          this.currentNotificationObject.type = notification.type
          break
        }
      }
    }
  }
}
</script>
<style>
.font-light {
  color: white !important;
}
.contact-box {
  padding: 0 10px 0 10px !important;
}
.contact-time-font {
  font-size: 8px;
}
.limit-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.unread-messages-text{
  font-size: 10px;
  font-weight: bolder;
  color: red !important;
}
</style>
