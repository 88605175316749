import { socialvue } from '@/config/pluginInit'
import ChatItem from '../../../components/Chat/ChatItem'
import ToggleButton from '../../../components/Chat/ToggleButton'
import ToggleContent from '../../../components/Chat/ToggleContent'
import User from '../../../Model/User'
import { mapActions, mapGetters } from 'vuex'
import VueDraggableResizable from 'vue-draggable-resizable'
import FileUpload from '@/components/FileUpload/index.vue'

export default {
  name: 'MessageModal',
  components: { ChatItem, ToggleButton, ToggleContent, FileUpload, VueDraggableResizable },
  data () {
    return {
      search: '',
      user: new User({ id: 1, name: 'Anna Sthesia', role: 'Developer', image: require('../../../assets/images/user/user-08.jpg'), isActive: true }),
      usersList: [],
      searchedUsers: [],
      searchedMessage: [],
      contactType: 'CONTACT',
      searchedContact: 'SEARCHED_CONTACT',
      attachment: '',
      searchedMessageDisplay: 'SEARCHED_MESSAGE',
      chat: [],
      currentPage: 0,
      message: '',
      currentSelectedUserId: 5,
      profileUrl: '',
      temp: {
        old_unique_date: null
      },
      modalHeaderColor: 'warning',
      showAttachmentModal: false,
      modalBodyVariant: 'dark',
      modalHeaderText: 'light'
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentPage',
      'getIndividualNotificationCount',
      'getMessageAttachment',
      'getContactId',
      'getAttachmentModal',
      'getSearchUsers',
      'getSentMessageResponse',
      'getSearchStatus',
      'getLoadingChatHistoryStatus',
      'getCurrentChatUser',
      'getContactPageData',
      'getOpenMessageModal',
      'getContacts',
      'getUserMessageHistory',
      'getUser'

    ]),
    filteredList () {
      return this.usersList.filter(item => {
        return item.users.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    publicList () {
      return this.getContacts
    },
    searchedContacts () {
      return this.searchedUsers
    },
    searchedMessages () {
      return this.searchedMessage
    },
    messageToggle () {
      return this.getOpenMessageModal
    },
    updatedChatHistory () {
      if (this.getLoadingChatHistoryStatus) {
        this.chat = this.getUserMessageHistory
        return this.chat
      } else {
        this.chat = []
      }
    }
  },
  watch: {
    search () {
      if (this.search === '') {
        console.log('Search is Null')
        this.$store.dispatch('SET_SEARCH_STATUS', false)
      }
    },
    getMessageAttachment () {
      if (this.getMessageAttachment !== '') {
        console.log('Attachment Received')
        this.attachment = this.getMessageAttachment
        this.sendMessage()
      }
    },
    getIndividualNotificationCount () {
      if (this.getIndividualNotificationCount !== '') {
        console.log('Individual count', this.getIndividualNotificationCount.payload)
      }
    },
    getAttachmentModal () {
      this.showAttachmentModal = this.getAttachmentModal
    },
    showAttachmentModal () {
      console.log('Value changes ', this.showAttachmentModal)
      this.$store.dispatch('SET_ATTACHMENT_MODAL_STATE', this.showAttachmentModal)
      this.showAttachmentModal = this.getAttachmentModal
    }
  },
  methods: {
    ...mapActions([
      'SET_INDIVIDUAL_NOTIFICATION_COUNT',
      'SEARCH_USER',
      'SEND_MESSAGE',
      'SET_ATTACHMENT_MODAL_STATE',
      'GET_USER_MESSAGE_HISTORY_SECOND',
      'SET_CURRENT_PAGE',
      'GET_USER_MESSAGE_HISTORY',
      'SET_SEARCH_STATUS',
      'GET_CONTACTS'
    ]
    ),
    extractTime (date) {
      var newdate = new Date(date.createdDate).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
      var constructTime = newdate.getHours() + ':' + newdate.getMinutes()
      return constructTime
    },
    getNow: function () {
      const today = new Date()
      const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear()
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = date + ' ' + time
      return dateTime
    },
    checkUser (item, type) {
      // console.log('Check user' + item)
      let user = this.usersList.find(user => user.id === item)
      let final
      if (user !== undefined) {
        switch (type) {
          case 'name':
            final = user.name
            break
          case 'image':
            final = user.image
            break
          case 'role':
            final = user.role
            break
        }
        return final
      }
      return require('../../../assets/images/user/user-05.jpg')
    },
    fetchNextPage () {
      this.$store.dispatch('SET_CURRENT_PAGE', this.getCurrentPage + 1)
      console.log('Current Page number' + this.getCurrentPage)
      let currentPayload = this.getContactPageData[this.getCurrentPage]
      this.GET_USER_MESSAGE_HISTORY_SECOND({
        topicId: this.getContactId,
        payload: currentPayload,
        option: 'APPEND',

        success: () => {
          this.$store.dispatch('SET_CHAT_HISTORY_LOADING', true)
        }
      })
    },
    groupByDate (dateString) {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      let newdate = this.formatDate(dateString)
      var day = ('0' + newdate.getDate()).slice(-2)
      var dateData = monthNames[newdate.getMonth()] + ' ' + day + ', ' + newdate.getFullYear()

      if (this.old_unique_date === dateData) {
        return ''
      }

      // eslint-disable-next-line no-return-assign
      return this.old_unique_date = dateData
    },
    formatDate (dateString) {
      let date = dateString.split(' ')[0]
      let time = dateString.split(' ')[1]
      let d = date.split('-')[0]
      let m = date.split('-')[1]
      let y = date.split('-')[2]
      let newdate = new Date(y + '-' + m + '-' + d + 'T' + time + '.000+00:00')
      return newdate
    },
    getFormattedTime (dateString) {
      let newdate = this.formatDate(dateString)
      let newTime = newdate.getHours() + ':' + String(newdate.getMinutes()).padStart(2, '0')
      return newTime
    },
    searching () {
      this.SEARCH_USER({
        params: {
          'query': this.search
        },
        success: () => {
          this.$store.dispatch('SET_SEARCH_STATUS', true)
          this.searchedUsers = this.getSearchUsers.users
          this.searchedMessage = this.getSearchUsers.conversations
        }
      })
    },
    sendMessage () {
      let payload
      let responseNewUser = {
        targetUsername: '',
        message: ''
      }
      let responseOldUser = {
        topicId: '',
        message: ''
      }
      if (this.message !== '' || this.attachment !== '') {
        if (this.getCurrentChatUser.chatId) {
          if (this.attachment !== '') {
            responseOldUser.message = this.attachment
          } else {
            responseOldUser.message = this.message
          }
          responseOldUser.topicId = this.getCurrentChatUser.chatId
          payload = responseOldUser
        } else {
          responseNewUser.message = this.message
          if ('users' in this.getCurrentChatUser) {
            responseNewUser.targetUsername = this.getCurrentChatUser.users[0].username
          } else {
            responseNewUser.targetUsername = this.getCurrentChatUser.username
          }
          payload = responseNewUser
        }
        this.SEND_MESSAGE({
          payload: payload,
          success: () => {
            this.updatedChatHistory.push(this.getSentMessageResponse)
            console.log('Message Sent')
            this.message = ''
            this.attachment = ''
          }
        })
      }
    },
    closeMessageModal () {
      this.$store.dispatch('SET_MESSAGE_MODAL_STATE', false)
    }
  },
  beforeDestroy () {
    this.$store.dispatch('SET_CURRENT_PAGE', 0)
  },
  created () {
    console.log('Created')
    this.GET_CONTACTS()
    socialvue.index()
  }
}
